/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
import React from "react"
import ImageMeta from "../components/ImageMeta"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"
import TextPhoto from "../components/TextPhoto/TextPhoto"
import GetStarted from "../components/CTA/GetStarted"
import OffsetHeadingCTA from "../components/CTA/OffsetHeadingCTA"
import SEO from "../components/seo"

import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext
} from "cloudinary-react"

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
const converter = new showdown.Converter()

export default ({ data, location }) => {
  const post = data.allUniquePagesJson.nodes[0]

  let language = "en"

  return (
    <SharedStateProvider>
      <Layout pageTitle="first-visit">
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
          schemaPage={post.schemaPage ? post.schemaPage : null}
        />
        <section className="joshua-tree-content top-section section color-back text-section is-mobile">
          <div className="columns has-text-centered">
            <div className="column">
              <h1>{post.heading}</h1>
            </div>
          </div>

          <div className="columns">
            <div className="column is-3"></div>
            <div
              className="column"
              dangerouslySetInnerHTML={createHtml(
                converter.makeHtml(post.topBlurb)
              )}></div>
            <div className="column is-3"></div>
          </div>
        </section>

        <div className="joshua-tree-content your-first-visit">
          <section className="body-section" style={{ paddingTop: "5rem" }}>
            <TextPhoto
              columnsClasses="is-vcentered reverse-columns"
              topDown={false}
              sideColumnIs={3}
              centerColumnIs={1}
              leftColumn={
                <div className="column">
                  <h2 className="">{post.prepareFirstAppointment.heading}</h2>
                  <div
                    dangerouslySetInnerHTML={createHtml(
                      converter.makeHtml(post.prepareFirstAppointment.text)
                    )}></div>
                  <ButtonGroup tight alignRight noReverse>
                    <Button
                      buttonText={
                        post.prepareFirstAppointment.buttonOne.buttonText
                      }
                      href={post.prepareFirstAppointment.buttonOne.href}
                      contained
                    />
                    <Button
                      buttonText={
                        post.prepareFirstAppointment.buttonTwo.buttonText
                      }
                      href={post.prepareFirstAppointment.buttonTwo.href}
                      external
                    />
                  </ButtonGroup>
                </div>
              }
              rightColumn={
                <div className="column">
                  <div className="cls__first-visit">
                    <ImageMeta
                      cloudName="nuvolum"
                      className="is-fullwidth-mobile"
                      publicId={post.prepareFirstAppointment.imageId}
                      responsive
                      width="auto"></ImageMeta>
                  </div>
                </div>
              }
            />
          </section>
          <section className="body-section">
            <TextPhoto
              columnsClasses="is-vcentered"
              sideColumnIs={2}
              centerColumnIs={1}
              leftColumn={
                <div className="column is-10">
                  <ImageMeta
                    cloudName="nuvolum"
                    className="is-fullwidth-mobile"
                    publicId={post.itemsToBring.imageId}
                    width="auto"></ImageMeta>
                </div>
              }
              rightColumn={
                <div className="column is-9 right-column-under">
                  <h3>{post.itemsToBring.heading}</h3>
                  <div
                    dangerouslySetInnerHTML={createHtml(
                      converter.makeHtml(post.itemsToBring.text)
                    )}></div>
                </div>
              }
            />
          </section>

          <section className="body-section" style={{ paddingTop: "0" }}>
            <div className="columns">
              <div className="column is-3"></div>
              <div className="column">
                <h3 style={{ marginBottom: "1.5rem", marginTop: "0" }}>
                  {post.whatToExpect.heading}
                </h3>
              </div>
            </div>
            <div className="columns">
              <div className="column is-3"></div>
              <div
                className="column"
                dangerouslySetInnerHTML={createHtml(
                  converter.makeHtml(post.whatToExpect.text)
                )}></div>
              <div className="column is-3"></div>
            </div>
          </section>

          {/* <div className="columns">
            <div className="column is-offset-2 is-20">
              <div className="hl"></div>
            </div>
          </div> */}

          <section className="body-section color-back">
            <div className="columns">
              <div className="column is-3"></div>
              <div className="column">
                <h3 style={{ marginBottom: "1.5rem", marginTop: "0" }}>
                  {post.financialInsurancePolicy.heading}
                </h3>
              </div>
            </div>
            <div className="columns">
              <div className="column is-3"></div>
              <div
                className="column"
                dangerouslySetInnerHTML={createHtml(
                  converter.makeHtml(post.financialInsurancePolicy.text)
                )}></div>
              <div className="column is-3"></div>
            </div>
          </section>

          <section className="body-section">
            <GetStarted
              headingSideColumnIs={4}
              sideColumnIs={4}
              centerHeading
              heading={
                language === "es"
                  ? getStartedDataEs.heading
                  : post.getStarted.heading
              }
              paragraph={
                <MarkdownViewer
                  markdown={
                    language === "es"
                      ? getStartedDataEs.blurb
                      : post.getStarted.blurb
                  }
                />
              }
              buttonText={
                language === "es"
                  ? getStartedDataEs.buttonText
                  : post.getStarted.buttonText
              }
              buttonUrl={
                language === "es" ? getStartedDataEs.href : post.getStarted.href
              }
            />
          </section>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query yourFirstVisitPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        heading
        topBlurb
        metaTitle
        metaDescription
        schemaPage
        title
        financialInsurancePolicy {
          heading
          text
        }
        prepareFirstAppointment {
          imageId
          text
          heading
          buttonOne {
            buttonText
            href
          }
          buttonTwo {
            buttonText
            href
          }
        }
        itemsToBring {
          heading
          imageId
          text
        }
        whatToExpect {
          heading
          text
        }
        getStarted {
          blurb
          buttonText
          heading
          href
          subheading
        }
      }
    }
  }
`
